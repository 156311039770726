"use client";
import { ReactNode } from "react";

import { MobileMenuProvider } from "@lib/context/mobile-menu-context";
import { ThemeContextProvider } from "./theme-provider";

const Providers = ({ children }: { children: ReactNode }) => {
	return (
		<ThemeContextProvider>
			<MobileMenuProvider>{children}</MobileMenuProvider>
		</ThemeContextProvider>
	);
};

export default Providers;
